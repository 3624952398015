.hidden
  display none

.categories-container
  display flex

  .category-layout
    display inline-grid
    grid-template-rows auto
    grid-template-columns repeat(4, 1fr)
    grid-template-areas 1fr
    align-items flex-start
    padding 10px 30px

    .category-item
      display flex
      margin 20px 30px 30px 0
      min-width 220px
      text-wrap wrap
      a
        flex-wrap nowrap
        display flex
        align-items flex-start

    .category-products
      display flex
      flex-direction column
      margin-top 7px

      a
        margin 3px 0
        font-size 13px
        .product-card
          font-size 13px
          margin 2px 0

@media (max-width: 1200px)
  .categories-container
    .category-layout
      grid-template-columns repeat(3, 1fr)
@media (max-width: 950px)
  .categories-container
    .category-layout
      grid-template-columns repeat(2, 1fr)
@media (max-width: 600px)
  .categories-container
    .category-layout
      grid-template-columns 1fr
      .category-item
        margin 10px 30px 30px 0
        min-width 300px
        text-wrap wrap
