@require './variables'
@require './home'
@require './hero'
@require './home-search'
@require './home-search-platforms'
@require './collapse-section'
@require './category-list'
@require './card'

body
	padding 0 !important

// Navigation Bar style overrides
.navigation
	z-indez 2
	img
		margin-right 25px !important
	.navbar-avatar
		margin-left 0 !important

	a:hover
		color inherit

.navigation :nth-child(3)
	padding 0

.navigation :nth-child(3)
	padding 0

.aside-group
	display flex
	align-items center
	input, label
		width 500px
		height 35px
		margin-right 30px

.dropdown-button
	display: inline-block
	padding: 14px 25px
	background-color: transparent
	cursor: pointer
	line-height: 16px
	border: 1px solid rgba(0, 0, 0, 0)
.dropdown-section
	position: relative
	color: #111
	cursor: pointer
	text-decoration: none

	&:hover
		background-color: white

	.dropdown-button:hover
		border-color: white

.support-dropdown-content
	display none
	width 100%
	left -1px
	margin 0
	padding 0
	position absolute
	list-style none
	border-radius 0 0 15px 15px
	border-color-top none
	background-color white
	padding-bottom 10px
	box-shadow 1px 1px 2px ep-grey-2

	li
		border-color white
		text-align center
		width 100%
		background-color white
		cursor pointer
		border none

		&:hover
			background-color: #ECECEC

		a
			width 100%
			padding 10px 0px
			border none
			display inline-block

			&:hover
				background-color transparent

.dropdown-section div:hover + ul, .dropdown-section ul:hover
	display: block

.dropdown-section div:hover + ul, .dropdown-section ul:hover
	display: block

// Loading spinner for GQL Query component
.query-spinner
	margin 10% auto !important

a
	color black
	text-decoration none
	&:hover
		color ep-grey-7

.Toastify__toast-container
	border-radius 5px

.Toastify__toast.Toastify__toast--success
	border-radius 5px
	background #48BD1F


// Taken from animate-css; was only using these so I just extracted these
@keyframes slideInLeft {
  from {
    transform: translate3d(-100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInLeft {
  animation-name: slideInLeft;
}

@keyframes slideInRight {
  from {
    transform: translate3d(100%, 0, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInRight {
  animation-name: slideInRight;
}

@keyframes slideOutLeft {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(-100%, 0, 0);
  }
}

.slideOutLeft {
  animation-name: slideOutLeft;
}

@keyframes slideOutRight {
  from {
    transform: translate3d(0, 0, 0);
  }

  to {
    visibility: hidden;
    transform: translate3d(100%, 0, 0);
  }
}

.slideOutRight {
  animation-name: slideOutRight;
}

.star-button
	background-color white !important
	padding 0 !important
	min-width 30px !important
	margin-left 10px !important
	i
		font-size 20px
		color ep-grey-4
	.saved-star
		color #f1c40f