.hero
	background radial-gradient(circle, rgba(81,151,214,1) 0%, rgba(81,167,214,1) 100%)
	padding 50px 0
	height 70px
	display flex
	align-items center

	.hero-content
		display flex
		align-items flex-start
		color white
		svg
			margin-right 40px
			margin-top 8px
		h1
			font-size 50px
