.content-wrapper
  height 100vh

.category-back-link
  display block

.home-body
  padding 20px 80px !important
  min-height calc(100vh - 180px) !important
  max-width 80vw

.home-toggle
  margin-top 3px
  line-height .8 !important
  height 90%
.home-toggle label
  font-size 12px
.home-toggle div
  font-size 12px
  line-height 1 !important

.layout
  display inline-grid
  grid-template-rows 60px
  grid-template-columns repeat(5, 1fr)
  grid-template-areas 1fr
  justify-content space-between
  align-items flex-start

.card-layout
  padding 20px 30px
  grid-template-rows 90px
  a
    .product-card
      display flex
      align-items flex-start

  &.has-summary
    grid-template-columns repeat(4, 1fr)
    font-size 14px
    grid-template-rows 150px
    grid-column-gap 50px
    min-height 700px // FIX this, replace with dynamic number according to height of category

    .product-card
      font-size 16px
      margin-bottom 10px

@media (min-width: 1300px)
  .container
    max-width 1200px !important

@media (max-width: 1200px)
  .layout
    grid-template-columns repeat(3, 1fr)
@media (max-width: 950px)
  .layout
    grid-template-columns repeat(2, 1fr)
@media (max-width: 800px)
  .layout
    grid-template-columns repeat(2, 1fr)
@media (max-width: 600px)
  .layout
    grid-template-columns 1fr
    .category-item
      margin 10px 30px 30px 0
      min-width 300px
      text-wrap wrap

@media (max-width: 1300px)
  .card-layout
    grid-template-columns repeat(4, 1fr)
@media (max-width: 1000px)
  .card-layout
    grid-template-columns repeat(3, 1fr)
@media (max-width: 800px)
  .card-layout
    grid-template-columns repeat(2, 1fr)
@media (max-width: 600px)
  .card-layout
    grid-template-columns 1fr

@media (max-width: 1300px)
  .card-layout.has-summary
    grid-template-columns repeat(3, 1fr)
@media (max-width: 950px)
  .card-layout.has-summary
    grid-template-columns repeat(2, 1fr)
@media (max-width: 600px)
  .card-layout.has-summary
    grid-template-columns 1fr