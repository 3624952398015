.collapse-section
  margin 20px 0 0 0
  width 100%

.trigger

  .trigger-icon-container
    position relative
    display flex
    align-items center
    width 100%
    overflow hidden

    i
      color ep-black

    .trigger-title
      width 100%
      font-size 15px

      .nested-header
        .nested-header-icon, .nested-header-link
          margin-right 10px
        .nested-header-link:hover
          color ep-grey-7

      &:after
        content ""
        height 2px
        width 100%
        position absolute
        background-color ep-grey-5
        top: 50%
        margin-top: -1px
        margin-left 20px
        display inline-block

  &:hover
    .trigger-title:not(.nested-trigger)
      color ep-grey-7
      cursor pointer
    .trigger-title.nested-trigger
      cursor default