search-width = 100%
search-height = 30px
search-left-offset = 43px
animation-speed = 0.5s
nav-items-padding = 30px
button-width = 42px

initial-border = 1px solid ep-grey-4 !important
focus-border = 1px solid pc-hover-blue !important

.home-search
  position relative
  display flex
  align-items center
  padding 10px 24px 10px 0
  width search-width

.home-search-group
  width search-width !important
  margin 0 0 30px 0 !important
  position relative

  .remove
    outline: none
    border: none
    cursor: pointer
    position: absolute
    background-color: transparent
    top: 6px
    left: calc(100% - 55px)
    color: ep-grey-4
    font-size: 14px
    transition color 0.3s ease
    z-index 2

    &:hover
      color: ep-grey-6

.home-search-input
  width 100% !important
  position relative !important

  button
    transition border-left 0.3s ease, border-top 0.3s ease, border-bottom 0.3s ease

  button:not(.remove)
    height 44px !important
    width button-width !important
    background-color white !important
    border initial-border
    border-right 1px solid ep-grey-1 !important
    i
      border none !important
      color ep-grey-5 !important
      transition color 0.3s ease
    div
      border-top 1px solid ep-grey-6 !important

    &:hover
      i
        color ep-grey-7 !important
  input
    height 40px !important
    border initial-border
    border-left none !important
    font-size 14px !important
    transition border 0.3s ease
    &:focus
      border focus-border
      border-left none !important
    &:focus + button:not(.remove)
      border focus-border
      border-right none !important
      i
        color pc-hover-blue !important
      div
        border-top 1px solid pc-hover-blue !important

  &.open
    input
      border-bottom-right-radius 20px !important
      border-bottom initial-border
      border-bottom-right-radius 0 !important
    input + button
      border-bottom-left-radius 0 !important

.search-results
  margin 53px 0 0  0
  padding 0 0 0 0
  cursor pointer
  display none
  position absolute
  z-index -1
  background-color ep-white
  top -100%
  opacity 0
  width calc(100% - 26px)
  height auto
  border-bottom-left-radius 20px
  border-bottom-right-radius 20px
  border focus-border
  border-top initial-border
  list-style none
  max-height 425px
  overflow scroll

  &:focus
    outline none

  &.open
    display initial
    top 0
    opacity 1
    z-index 2

  .search-result
    .dropdown-bold
      color #111
      display inline

    a, span
      display block
      color ep-grey-7
      height 100%
      padding 12px
      font-size 14px

    &:last-child
      border-bottom-left-radius 20px
      border-bottom-right-radius 20px

  .search-result.no-results
    padding 10px 10px
    background-color white

  .active-index:not(.hover-enabled)
    background-color #f2f3f3
    padding 25 0

  .search-result:hover
    &.hover-enabled:hover
      background-color #f2f3f3
      padding 25 0

    .card-img
      display flex
      align-items center
      justify-content center

.nav-search
  position relative
  width 400px
  margin 0 30px
  font-family Helvetica Neue
  label, input
    height 30px
  .search-results
    line-height 1.5
    text-transform none
    width 398px
    margin 37px 0 0  0
    z-index z-index-layer-10

  .home-search-input
    input
      height 37px !important

    button:not(.remove)
      height 41px !important
