.product-card
  display flex
  align-items center
  margin 20px 30px 30px 0
  min-width 200px
  .card-name
    display block
  .card-img
    min-width 60px
    margin-right: 10px
    img
      height 25px
      width auto